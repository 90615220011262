import { useState, useEffect } from "react";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";

export default function HowTable({ title, entries }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const filteredEntries = entries.slice(0, 11);

  const handleToggle = () => {
    window.location.href = '/signin';
  };
  useEffect(() => {
    function checkLogin() {
      onAuthStateChanged(auth, (user) => {
        setIsLoggedIn(!!user);
      });
    }

    checkLogin();
  });

  return (

    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <caption className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
          {title}
          <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">
            Number of entries: <b>{entries.length}</b>
          </p>
        </caption>
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              How They Got Promoted
            </th>
          </tr>
        </thead>
        <tbody>

          {isLoggedIn ? (
            filteredEntries.map((entry, index) => (
              <tr
                key={index}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td className="px-6 py-4">
                  {entry}
                </td>
              </tr>
            ))
          ) : (
            <>
              <div class="flex justify-center space-x-2">
                <h1 className="text-xl font-bold mb-4">Log in to see extra information on how others got promoted</h1>

              </div>
              <div class="flex justify-center space-x-2">
                <button onClick={handleToggle} className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
                  Log In
                </button>
              </div>
            </>
          )}
        </tbody>

      </table>
    </div>
  );
}
import React, { useState, useEffect } from 'react';

export default function EducationForm({ setSchool, setDegree, setMajor, setYear, errorMessage, setErrorMessage }) {
    function handleDegree(e) {
        setSchool(e.target.value);
        setErrorMessage('');
    }

    return (
        <div>
            <h1 className="text-base font-semibold leading-7 pb-2 text-gray-900">Education</h1>

            <div class="grid md:grid-cols-3 md:gap-6">
                <div class="relative z-0 w-full mb-6 group">
                    <label for="school" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">University</label>
                    <input type="text" name="school" id="school" aria-describedby="helper-text-explanation" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        onChange={e => handleDegree(e)}
                        placeholder="University of Washington" required />
                </div>
                <div class="relative z-0 w-full mb-6 group">
                    <label for="degree" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Degree</label>
                    <select type="text" name="degree" id="degree" aria-describedby="helper-text-explanation" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errorMessage && errorMessage.id == "degree" ? 'border-red-600 dark:border-red-500' : ''}`}
                        onChange={e => setDegree(e.target.value)}
                        placeholder="" required>
                        <option value="" selected="selected" disabled="disabled">-- select one --</option>
                        <option value="No formal education">No formal education</option>
                        <option value="Primary education">Primary education</option>
                        <option value="Secondary education">Secondary education or high school</option>
                        <option value="GED">GED</option>
                        <option value="Vocational qualification">Vocational qualification</option>
                        <option value="Bachelor's degree">Bachelor's degree</option>
                        <option value="Master's degree">Master's degree</option>
                        <option value="Doctorate or higher">Doctorate or higher</option>
                    </select>
                </div>
                <div class="relative z-0 w-full mb-6 group">
                    <label for="floating_major" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Major</label>
                    <input type="text" name="floating_major" id="floating_major" aria-describedby="helper-text-explanation" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        onChange={e => setMajor(e.target.value)}
                        placeholder="Computer Science" required />
                </div>
                <div class="relative z-0 w-full mb-6 group">
                    <label for="floating_year" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Year Graduated</label>
                    <input type="month" name="floating_year" id="floating_year" aria-describedby="helper-text-explanation" min="1960-01" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        onChange={e => setYear(e.target.value)}
                        placeholder=" " required />
                </div>
            </div>
        </div>
    )
}

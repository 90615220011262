import React from "react";
import "../../css/Spinner.css";
import { useEffect, useState } from "react";

const Spinner = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false); // Set loading state to false after a specific duration (e.g., 2 seconds)
    }, 300);

    return () => clearTimeout(timeout); // Clear the timeout if the component unmounts before the duration elapses
  }, []);

  if (isLoading) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );
  } else {
    return <p>No data available</p>;
  }
};

export default Spinner;
import React from "react";
import { useEffect } from "react";
import { Pie } from "react-chartjs-2";

function PieChart({ title, chartData, x }) {
  // useEffect(() => {
  //   if (x) {
  //     console.log(x);
  //   }
  // })

  return (
    <div className="chart-container">
      <h2 style={{ textAlign: "center" }}>{title}</h2>
      <Pie
        data={chartData}
        options={{
          plugins: {
            title: {
              display: true,
              // text: "Users Gained between 2016-2020"
            }
          }
        }}
      />
    </div>
  );
}
export default PieChart;
import { raceOptions, genderOptions } from "../Constants";

export default function DemographicForm({ dob, setDob, setRace, setGender, errorMessage, setErrorMessage }) {
    function handleDob(e) {
        setDob(e.target.value);
        setErrorMessage('');
    }

    return (
        <div>
            <h1 className="text-base font-semibold leading-7 pb-2 text-gray-900">Demographics</h1>

            <div class="grid md:grid-cols-3 md:gap-6">
                <div class="relative z-0 w-full mb-6 group">
                    <label for="age" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Date of Birth</label>
                    <input type="date" name="age" id="age" value={dob} min="1960-01-01" aria-describedby="helper-text-explanation"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errorMessage && errorMessage.id == "age" ? 'border-red-600 dark:border-red-500' : ''}`}
                        onChange={e => handleDob(e)}
                        placeholder=" " required />
                </div>
                <div class="relative z-0 w-full mb-6 group">
                    <label for="race" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Race</label>
                    <select type="text" name="race" id="race" aria-describedby="helper-text-explanation" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errorMessage && errorMessage.id == "race" ? 'border-red-600 dark:border-red-500' : ''}`}
                        onChange={e => setRace(e.target.value)}
                        placeholder=" " required>
                        <option value="" selected="selected" disabled="disabled">-- select one --</option>
                        {Object.values(raceOptions).map((race) => (
                            <option key={race} value={race}>
                                {race}
                            </option>
                        ))}
                    </select>
                </div>
                <div class="relative z-0 w-full mb-6 group">
                    <label for="gender" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Gender</label>
                    <select type="text" name="gender" id="gender" aria-describedby="helper-text-explanation" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errorMessage && errorMessage.id == "gender" ? 'border-red-600 dark:border-red-500' : ''}`}
                        onChange={e => setGender(e.target.value)}
                        placeholder=" " required >
                        <option value="" selected="selected" disabled="disabled">-- select one --</option>
                        {Object.values(genderOptions).map((gender) => (
                            <option key={gender} value={gender}>
                                {gender}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    )
}

import React from "react";
import "../../css/Spinner.css";

const colorPalette = [
  "#007BFF", // Blue
  "#17C3B2", // Teal
  "#FFC857", // Yellow
  "#E83F6F", // Pink
  "#6610F2", // Purple
  "#20C997", // Green
  "#FF6B6B", // Red
  "#7952B3", // Indigo
  "#343A40", // Dark Gray
  "#6C757D", // Gray
  "#17A2B8", // Cyan
  "#FF9F1C", // Orange
  "#FF5E57", // Coral
  "#3C40C6", // Royal Blue
  "#FD9644", // Carrot Orange
  "#8854D0", // Deep Purple
  "#26BFBF", // Turquoise
  "#FF4B8A", // Hot Pink
  "#A55eea", // Light Purple
  "#6AB04c", // Lime Green
  "#4D8FAC", // Steel Blue
  "#00B8D9", // Aqua Blue
  "#FF512F", // Tomato Red
  "#8338EC", // Deep Indigo
  "#EF476F", // Salmon Pink
  "#28D094", // Mint Green
  "#5f27cd", // Light Indigo
  "#F77F00", // Dark Orange
  "#222f3e", // Navy Blue
];

export function getColor(index) {
  const colorIndex = index % colorPalette.length;
  return colorPalette[colorIndex];
}

import JobHistoryForm from '../../components/JobHistory';
import Root from './Root';

export default function AddJobHistory() {
    return (
        <>
        {/* <Root /> */}
        <JobHistoryForm />
        </>
    )
}
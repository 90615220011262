import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Root from './routes/careermap/Root';
import Home from './routes/careermap/Home';
import Thanks from './routes/careermap/Thanks';
import Admin from './routes/careermap/Admin';
import { SignInForm } from './routes/careermap/SignIn';
import { SignUpForm } from './routes/careermap/SignUp';
import AddJobHistory from './routes/careermap/AddJobHistory';
import Dashboard from './routes/worksense/Dashboard';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Navbar from './components/Navbar';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />
    // element: <Root />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/addpromo",
    element: <AddJobHistory />,
  },
  {
    path: "/thanks",
    element: <Thanks />,
  },
  {
    path: "/secretadminpage",
    element: <Admin />,
  },
  {
    path: "/signin",
    element: <SignInForm />,
  },
  {
    path: "/signup",
    element: <SignUpForm />,
  },
  {
    path: "/worksense",
    element: <Dashboard />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
const showNavbar = (router.state.location.pathname == '/home' || router.state.location.pathname == '/signup' || router.state.location.pathname == '/signin' )

root.render(
  <React.StrictMode>
    {showNavbar && <Navbar />}
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export const levelOptions = {
  L3: 'L3',
  L4: 'L4',
  L5: 'L5',
  L6: 'L6',
  L7: 'L7',
  L8: 'L8',
  L9: 'L9',
  L10: 'L10',
};

export const raceOptions = {
  Asian: 'Asian',
  SouthAsian: 'South Asian',
  EastAsian: 'East Asian',
  Hispanic: 'Hispanic or Latino',
  White: 'White',
  Native: 'American Indian or Alaska Native',
  Black: 'Black or African American',
  Pacific: 'Native Hawaiian or Other Pacific Islander',
  MiddleEast: 'Middle Eastern',
  NA: 'N/A',
};

export const genderOptions = {
  Male: 'Male',
  Female: 'Female',
  Other: 'Other'
}

export const techCompanies = new Set([
  "Microsoft",
  "Apple Inc.",
  "Google",
  "Amazon",
  "Facebook",
  "IBM",
  "Intel",
  "Oracle",
  "Cisco Systems",
  "Adobe Systems",
  "Hewlett Packard Enterprise",
  "SAP SE",
  "Dell Technologies",
  "NVIDIA",
  "Salesforce",
  "Twitter",
  "Netflix",
  "Airbnb",
  "Uber Technologies",
  "PayPal Holdings",
  "LinkedIn",
  "Dropbox",
  "Slack Technologies",
  "Square Inc.",
  "Zoom Video Communications",
  "Atlassian",
  "GitHub",
  "Reddit",
  "Snapchat",
  "Pinterest",
  "Tesla Inc.",
  "Shopify",
  "Xiaomi",
  "T-Mobile US",
  "Samsung Electronics",
  "Verizon Communications",
  "AT&T",
  "Sony Corporation",
  "LG Electronics",
  "Motorola Solutions",
  "Electronic Arts",
  "Activision Blizzard",
  "Electronic Data Systems (EDS)",
  "Accenture",
  "Capgemini",
  "Infosys",
  "Cognizant",
  "Wipro",
  "Tata Consultancy Services (TCS)",
  "HCL Technologies",
  "VMWare",
  "Qualcomm",
  "Broadcom",
  "Advanced Micro Devices (AMD)",
  "Micron Technology",
  "Western Digital",
  "Seagate Technology",
  "Juniper Networks",
  "McAfee",
  "Symantec",
  "Palo Alto Networks",
  "Check Point Software Technologies",
  "Fortinet",
  "F5 Networks",
  "Akamai Technologies",
  "Citrix Systems",
  "Autodesk",
  "Siemens AG",
  "General Electric (GE)",
  "Honeywell International",
  "Philips",
  "Panasonic Corporation",
  "Toshiba Corporation",
  "Fujitsu",
  "NEC Corporation",
  "Hitachi Ltd.",
  "Ericsson",
  "Nokia",
  "ZTE Corporation",
  "Huawei Technologies",
  "Alibaba Group",
  "Baidu",
  "Tencent Holdings",
  "JD.com",
  "Meituan Dianping",
  "Naver Corporation",
  "Kakao Corporation",
  "SoftBank Group",
  "Rakuten Inc.",
  "Spotify",
  "Dropbox",
  "GoDaddy",
  "Zoho Corporation",
  "Squarespace",
  "Box Inc.",
  "Qualtrics",
  "Okta Inc.",
  "Twilio",
  "Cloudera",
  "MongoDB Inc.",
  "Slack Technologies",
  "Splunk Inc.",
  "Databricks",
  "Snowflake Inc.",
  "ZoomInfo",
  "Proofpoint",
  "Qualys",
  "Elastic N.V.",
  "Crowdstrike",
  "Cloudflare",
  "AppDynamics",
  "Dynatrace",
  "GitLab",
  "Couchbase",
  "ThoughtWorks",
  "ThoughtSpot",
  "HashiCorp",
  "Docker Inc.",
  "Kong Inc.",
  "Nutanix",
  "Rubrik Inc.",
  "Lookout Inc.",
  "Atlassian",
  "Okta Inc.",
  "Anaplan Inc.",
  "Unity Technologies",
  "Tableau Software",
  "McAfee",
  "Twilio",
  "Dropbox",
  "DocuSign",
  "UiPath",
  "Cloudera",
  "Splunk Inc.",
  "Zendesk",
  "PagerDuty",
  "DocuSign",
  "ServiceNow",
  "Okta Inc.",
  "Zscaler",
  "PagerDuty",
  "Fastly",
  "DocuSign",
  "Elastic N.V.",
  "Twilio",
  "Snowflake Inc.",
  "DocuSign",
  "Atlassian",
  "Splunk Inc.",
  "Twilio",
  "Lookout Inc.",
  "Alteryx",
  "Box Inc.",
  "JFrog",
  "Medallia",
  "Nutanix",
  "Okta Inc.",
  "Palo Alto Networks",
  "Qualys",
  "Rapid7",
  "RingCentral",
  "SailPoint Technologies",
  "Slack Technologies",
  "SolarWinds",
  "Tanium",
  "Tenable",
  "ThoughtWorks",
  "Twilio",
  "Veracode",
  "Zerto",
  "Zuora",
  "Avast Software",
  "Bentley Systems",
  "Carbon Black",
  "Cornerstone OnDemand",
  "DigitalOcean",
  "Druva Inc.",
  "Exabeam",
  "GitHub",
  "Informatica",
  "Ivanti",
  "Lookout Inc.",
  "Mimecast",
  "MongoDB Inc.",
  "MuleSoft",
  "New Relic",
  "OpenText",
  "OutSystems",
  "ServiceNow",
  "Twilio",
  "Anaplan Inc.",
  "Unity Technologies",
  "Tableau Software",
  "McAfee",
  "Twilio",
  "Dropbox",
  "DocuSign",
  "UiPath",
  "Cloudera",
  "Splunk Inc.",
  "Zendesk",
  "PagerDuty",
  "DocuSign",
  "ServiceNow",
  "Okta Inc.",
  "Zscaler",
  "PagerDuty",
  "Fastly",
  "DocuSign",
  "Elastic N.V.",
  "Twilio",
  "Snowflake Inc.",
  "DocuSign",
  "Atlassian",
  "Splunk Inc.",
  "Twilio",
  "Lookout Inc.",
  "Alteryx",
  "Box Inc.",
  "JFrog",
  "Medallia",
  "Nutanix",
  "Okta Inc.",
  "Palo Alto Networks",
  "Qualys",
  "Rapid7",
  "RingCentral",
  "SailPoint Technologies",
  "Slack Technologies",
  "SolarWinds",
  "Tanium",
  "Tenable",
  "ThoughtWorks",
  "Twilio",
  "Veracode",
  "Zerto",
  "Zuora",
  "Avast Software",
  "Bentley Systems",
  "Carbon Black",
  "Cornerstone OnDemand",
  "DigitalOcean",
  "Druva Inc.",
  "Exabeam",
  "GitHub",
  "Informatica",
  "Ivanti",
  "Lookout Inc.",
  "Mimecast",
  "MongoDB Inc.",
  "MuleSoft",
  "New Relic",
  "OpenText",
  "OutSystems",
  "PagerDuty",
  "Proofpoint",
  "Rapid7",
  "Red Hat",
  "Salesforce",
  "ServiceNow",
  "Sina Corporation",
  "Smartsheet",
  "Snap Inc.",
  "SolarWinds",
  "Square Inc.",
  "Symantec",
  "Synopsys",
  "T-Mobile US",
  "Tableau Software",
  "Tata Consultancy Services (TCS)",
  "Teradata",
  "ThoughtWorks",
  "Twilio",
  "UiPath",
  "Verizon Communications",
  "VMWare",
  "Workday",
  "Zillow Group",
  "Zscaler",
  "InVision",
  "Snowflake Inc.",
  "Hashicorp",
  "Airtable",
  "Auth0",
  "Veeva Systems",
  "C3.ai",
  "Sprinklr",
  "Confluent",
  "Couchbase",
  "UiPath",
  "QuantumBlack",
  "Automation Anywhere",
  "Outreach",
  "Snyk",
  "Zuora",
  "Netskope",
  "Celonis",
  "Sumo Logic",
  "Elastic N.V.",
  "Rubrik",
  "Zerto",
  "Druva",
  "Cohesity",
  "ZScaler",
  "Squarespace",
  "Canva",
  "Betterment",
  "Zapier",
  "Zenefits",
  "Hootsuite",
  "Calendly",
  "Intercom",
  "PagerDuty",
  "RingCentral",
  "SendGrid",
  "Cloudera",
  "DigitalOcean",
  "UiPath",
  "Alation",
  "MuleSoft",
  "Acquia",
  "Twilio",
  "Looker",
  "Wrike",
  "Okta",
  "Auth0",
  "ThoughtSpot",
  "Cloudflare",
  "Figma",
  "HashiCorp",
  "Fivetran",
  "Plaid",
  "Auth0",
  "Stripe",
  "DataRobot",
  "Pulumi",
  "Collibra",
  "Samsara",
  "Squarespace",
  "Asana",
  "Roblox",
  "Datadog",
  "UiPath",
  "Airbnb",
  "Ceridian",
  "Veeam",
  "Snyk",
  "Couchbase",
  "PagerDuty",
  "Zuora",
  "ZScaler",
  "PagerDuty",
  "Rubrik",
  "HashiCorp",
  "Twilio",
  "UiPath",
]);
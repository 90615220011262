import './../css/App.css';
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { useState, useEffect } from "react";
import PieChart from './charts/PieChart';
import { BarChart } from './charts/BarChart';
import { doc, getDoc, getDocFromCache, getDocs, collection, orderBy, query } from "firebase/firestore";
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import { db } from '../firebase';
import Spinner from './util/Spinner';
import { levelOptions } from './Constants';
import { GroupedBarChart } from './charts/GroupedBarChart';
import { getColor } from './util/ColorPalette';
import HowTable from './HowTable';

Chart.register(CategoryScale);

function getAverageTotalExperiencePerLevel(entries, companyName) {
  const processEntry = (roleData, entry, company, role, companyIndex, roleIndex) => {
    let previousRolesTime = 0;

    // Iterate through previous companies and roles
    for (let i = 0; i < companyIndex; i++) {
      const previousCompany = entry.companies[i];
      if (previousCompany.roles) {
        // Iterate through all roles in previous company
        const roleLimit = i === companyIndex - 1 ? roleIndex : previousCompany.roles.length;
        for (let j = 0; j < roleLimit; j++) {
          const previousRole = previousCompany.roles[j];
          const previousRoleTime = previousRole.time;

          // Skip null or empty values
          if (previousRoleTime) {
            previousRolesTime += Number(previousRoleTime);
          }
        }
      }
    }

    const totalYears = Number(entry.other.totalYears);
    const value = totalYears - previousRolesTime;
    const level = role.level.toUpperCase();
    updateRoleData(roleData, level, value);
  };

  const setValue = 'averageYears';

  const roleData = processEntriesForRoles(entries, companyName, processEntry);
  return calculateAverageByRoleLevel(roleData, setValue);
}

function getAverageAgePerLevel(entries, companyName) {
  const processEntry = (roleData, entry, company, role, companyIndex, roleIndex) => {
    let currentRoleAge = 0;
    if (entry.demographic.age && !isNaN(entry.demographic.age)) {
      currentRoleAge = Number(entry.demographic.age);
    } else if (entry.demographic.dob) {
      currentRoleAge = calculateAge(entry.demographic.dob);
    }

    let previousRolesTime = 0;

    // Iterate through previous companies and roles
    if (currentRoleAge > 0) {
      for (let i = 0; i < companyIndex; i++) {
        const previousCompany = entry.companies[i];
        if (previousCompany.roles) {
          // Iterate through all roles in previous company
          const roleLimit = i === companyIndex - 1 ? roleIndex : previousCompany.roles.length;
          for (let j = 0; j < roleLimit; j++) {
            const previousRole = previousCompany.roles[j];
            const previousRoleTime = previousRole.time;

            // Skip null or empty values
            if (previousRoleTime) {
              previousRolesTime += Number(previousRoleTime);
            }
          }
        }
      }

      const value = currentRoleAge - previousRolesTime;
      const level = role.level.toUpperCase();
      updateRoleData(roleData, level, value);
    }
  };

  const setValue = 'averageAge';

  const roleData = processEntriesForRoles(entries, companyName, processEntry);
  return calculateAverageByRoleLevel(roleData, setValue);
}

function getAverageTimePerLevel(entries, companyName) {
  const processEntry = (roleData, entry, company, role, companyIndex, roleIndex) => {
    const time = Number(role.time);
    const level = role.level.toUpperCase();
    updateRoleData(roleData, level, time);
  };

  const setValue = 'averageTime';

  const roleData = processEntriesForRoles(entries, companyName, processEntry);
  return calculateAverageByRoleLevel(roleData, setValue);
}

function getTotalMajorsPerCompany(entries, companyName) {
  const processEntry = (roleData, entry, company, companyIndex) => {
    if (entry.education.major && entry.education.major != '') {
      const major = entry.education.major.toString().toLowerCase().trim();
      if (!roleData[major]) {
        roleData[major] = {
          major: major,
          count: 1,
        };
      } else {
        roleData[major].count += 1;
      }
    }
  };

  const majorsCountData = processEntries(entries, companyName, processEntry);
  return calculateTotalPerCompany(majorsCountData);
}

function getTopUniversitiesPerCompany(entries, companyName) {

  const processEntry = (roleData, entry, company, companyIndex) => {
    if (entry.education.school && entry.education.school != '') {
      const university = entry.education.school.toString().toLowerCase().trim();
      if (!roleData[university]) {
        roleData[university] = {
          university: university,
          count: 1,
        };
      } else {
        roleData[university].count += 1;
      }
    }
  };

  const majorsCountData = processEntries(entries, companyName, processEntry);

  const universityCountByCompany = [];

  Object.keys(majorsCountData).forEach((major) => {
    universityCountByCompany.push({
      id: major,
      universityCount: majorsCountData[major].count,
    });
  });

  return universityCountByCompany;
}

function getHighestCountsForPreviousCompany(entries, companyName) {
  const previousCompanyCount = {};

  entries.forEach((entry) => {
    entry.companies.forEach((company, companyIndex) => {
      if (companyName.toString().toLowerCase() === 'all' || company.name.toLowerCase() === companyName.toString().toLowerCase()) {
        // Get the previous companies
        const previousCompanies = entry.companies.slice(0, companyIndex);

        if (previousCompanies.length === 0 && companyName.toString().toLowerCase() !== 'all') {
          // Insert 'none' if no previous companies exist
          if (!previousCompanyCount['none']) {
            previousCompanyCount['none'] = {
              companyName: 'none',
              count: 1,
            };
          } else {
            previousCompanyCount['none'].count += 1;
          }
        } else {
          previousCompanies.forEach((prevCompany) => {
            if (!previousCompanyCount[prevCompany.name]) {
              previousCompanyCount[prevCompany.name] = {
                companyName: prevCompany.name,
                count: 1,
              };
            } else {
              previousCompanyCount[prevCompany.name].count += 1;
            }
          });
        }
      }
    });
  });

  const previousCompanies = [];

  Object.keys(previousCompanyCount).forEach((company) => {
    previousCompanies.push({
      id: company,
      count: previousCompanyCount[company].count,
    });
  });

  previousCompanies.sort((a, b) => b.count - a.count);

  return previousCompanies;
}

function getAllRacesForAllLevels(entries, companyName, passedLevel) {
  const processEntry = (roleData, entry, company, role, companyIndex, roleIndex) => {
    const level = role.level.toUpperCase();
    const race = entry.demographic.race ? entry.demographic.race.trim() : 'Unknown';

    if (!roleData[level]) {
      roleData[level] = {
        level: level,
        races: {}
      }
    } else {
      if (!roleData[level].races[race]) {
        roleData[level].races[race] = {
          race: race,
          count: 1,
        };
      } else {
        roleData[level].races[race].count += 1;
      }
    }
  };

  const raceCountForLevel = processEntriesForRoles(entries, companyName, processEntry);
  const formattedData = Object.values(raceCountForLevel).map((item) => {
    const groupLabel = item.level;
    const groupData = item.races ? Object.values(item.races).map((race) => ({
      label: race.race,
      data: [race.count]
    })) : [];

    return {
      label: groupLabel,
      data: groupData
    };
  });

  formattedData.sort((a, b) => a.label.localeCompare(b.label));

  return formattedData;
}

function getTopRacesPerLevel(entries, companyName, passedLevel) {
  const processEntry = (roleData, entry, company, role, companyIndex, roleIndex) => {
    const level = role.level.toUpperCase();
    const race = entry.demographic.race ? entry.demographic.race.trim() : 'Unknown';

    if (level === passedLevel) {
      if (!roleData[race]) {
        roleData[race] = {
          race: race,
          count: 1,
        };
      } else {
        roleData[race].count += 1;
      }
    }
  };

  const raceCountForLevel = processEntriesForRoles(entries, companyName, processEntry);
  return calculateTotalPerCompany(raceCountForLevel);
}

function getGenderPerLevel(entries, companyName, passedLevel) {
  const processEntry = (roleData, entry, company, role, companyIndex, roleIndex) => {
    const level = role.level.toUpperCase();
    const gender = entry.demographic.gender ? entry.demographic.gender.trim() : 'Unknown';

    if (level === passedLevel) {
      if (!roleData[gender]) {
        roleData[gender] = {
          gender: gender,
          count: 1,
        };
      } else {
        roleData[gender].count += 1;
      }
    }
  };

  const genderCountForLevel = processEntriesForRoles(entries, companyName, processEntry);
  return calculateTotalPerCompany(genderCountForLevel);
}

function getHowPromotedPerLevel(entries, companyName, passedLevel) {
  const howPromotedList = [];

  entries.forEach((entry) => {
    entry.companies.forEach((company, companyIndex) => {
      if (companyName.toString().toLowerCase() === 'all' || company.name.toLowerCase().includes(companyName.toString().toLowerCase())) {
        company.roles.forEach((role, roleIndex) => {
          const level = role.level.toUpperCase();
          const howPromoted = role.promotion;

          if (level === passedLevel && howPromoted) {
            howPromotedList.push(howPromoted);
          }
        });
      }
    });
  });

  console.log(howPromotedList);
  return howPromotedList;
}

// -------------------------Helper functions-------------------------------------
function processEntriesForRoles(entries, companyName, processEntry) {
  const roleData = {};

  entries.forEach((entry) => {
    entry.companies.forEach((company, companyIndex) => {
      if (companyName.toString().toLowerCase() === 'all' || company.name.toLowerCase().includes(companyName.toString().toLowerCase())) {
        company.roles.forEach((role, roleIndex) => {
          processEntry(roleData, entry, company, role, companyIndex, roleIndex);
        });
      }
    });
  });

  return roleData;
}

function processEntries(entries, companyName, processEntry) {
  const roleData = {};

  entries.forEach((entry) => {
    entry.companies.forEach((company, companyIndex) => {
      if (companyName.toString().toLowerCase() === 'all' || company.name.toLowerCase().includes(companyName.toString().toLowerCase())) {
        processEntry(roleData, entry, company, companyIndex);
      }
    });
  });

  return roleData;
}

function updateRoleData(roleData, level, value) {
  if (level && level !== '') {
    if (!roleData[level]) {
      roleData[level] = {
        totalValue: value,
        count: 1,
      };
    } else {
      roleData[level].totalValue += value;
      roleData[level].count += 1;
    }
  }
}

function calculateAverageByRoleLevel(roleData, setValue) {
  const averageByRoleLevel = [];

  Object.keys(roleData).forEach((level) => {
    const average = roleData[level].totalValue / roleData[level].count;
    const obj = {
      id: level,
    };
    obj[setValue] = average.toFixed(2);
    averageByRoleLevel.push(obj);
  });

  averageByRoleLevel.sort((a, b) => a.id.localeCompare(b.id));

  return averageByRoleLevel;
}

function calculateTotalPerCompany(data) {
  const totalByCompany = [];

  Object.keys(data).forEach((entry) => {
    const obj = {
      id: entry,
      count: data[entry].count
    }
    totalByCompany.push(obj);
  });

  return totalByCompany;
}

function calculateAge(birthdate) {
  const today = new Date();
  const birthDate = new Date(birthdate);

  let age = today.getFullYear() - birthDate.getFullYear();

  // Check if the birth month is ahead of the current month
  // or if it's the same month but the birth day is ahead of the current day
  if (birthDate.getMonth() > today.getMonth() || (birthDate.getMonth() === today.getMonth() && birthDate.getDate() > today.getDate())) {
    age--;
  }

  return age;
}

function getDemographicPerLevel(entries, companyName, selectedLevel, setSelectedLevel, setcountsForRacePerLevel, setcountsForRacePerLevelChartData, setCountsForGenderPerLevel, setCountsForGenderPerLevelChartData, setPromotionInfoPerLevel) {
  const countsForRacePerLevel = getTopRacesPerLevel(entries, companyName.companyName, selectedLevel);
  const countsForGenderPerLevel = getGenderPerLevel(entries, companyName.companyName, selectedLevel);
  const promotionInfoPerLevel = getHowPromotedPerLevel(entries, companyName.companyName, selectedLevel);

  setSelectedLevel(selectedLevel);

  setcountsForRacePerLevel(countsForRacePerLevel);
  setcountsForRacePerLevelChartData({
    labels: countsForRacePerLevel.map((data) => data.id),
    datasets: [
      {
        label: "Count",
        data: countsForRacePerLevel.map((data) => data.count),
        backgroundColor: countsForRacePerLevel.map((data, index) => getColor(index)),
        borderColor: "black",
        borderWidth: 2
      }
    ]
  });

  setCountsForGenderPerLevel(countsForGenderPerLevel);
  setCountsForGenderPerLevelChartData({
    labels: countsForGenderPerLevel.map((data) => data.id),
    datasets: [
      {
        label: "Count",
        data: countsForGenderPerLevel.map((data) => data.count),
        backgroundColor: countsForGenderPerLevel.map((data, index) => getColor(index)),
        borderColor: "black",
        borderWidth: 2
      }
    ]
  });

  setPromotionInfoPerLevel(promotionInfoPerLevel);
}

export default function App(companyName) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [entries, setEntries] = useState([]);

  const [totalExperiencePerLevel, setTotalExperiencePerLevel] = useState([]);
  const [totalExperienceChartData, setTotalExperienceChartData] = useState([]);

  const [averageAgePerLevel, setAverageAgePerLevel] = useState([]);
  const [averageAgePerLevelChartData, setAverageAgePerLevelChartData] = useState([]);

  const [averageTimePerLevel, setAverageTimePerLevel] = useState([]);
  const [averageTimePerLevelChartData, setAverageTimePerLevelChartData] = useState([]);

  const [totalMajorsPerLevel, setTotalMajorsPerCompany] = useState([]);
  const [totalMajorPerCompanyChartData, setTotalMajorPerCompanyChartData] = useState([]);

  const [universityCountPerCompany, setUniversityCountPerCompany] = useState([]);
  const [universityCountPerCompanyChartData, setUniversityCountPerCompanyChartData] = useState([]);

  const [countsForPreviousCompanies, setCountsForPreviousCompanies] = useState([]);
  const [countsForPreviousCompaniesChartData, setCountsForPreviousCompaniesChartData] = useState([]);

  const [countsForAllRacesForAllLevels, setCountsForAllRacesForAllLevels] = useState([]);
  const [countsForAllRacesForAllLevelsChartData, setCountsForAllRacesForAllLevelsChartData] = useState([]);

  const [selectedLevel, setSelectedLevel] = useState(levelOptions.L5);

  const [countsForRacePerLevel, setcountsForRacePerLevel] = useState([]);
  const [countsForRacePerLevelChartData, setcountsForRacePerLevelChartData] = useState([]);

  const [countsForGenderPerLevel, setCountsForGenderPerLevel] = useState([]);
  const [countsForGenderPerLevelChartData, setCountsForGenderPerLevelChartData] = useState([]);

  const [promotionInfoPerLevel, setPromotionInfoPerLevel] = useState([]);

  const handleToggle = () => {
    window.location.href = '/signin';
  };

  useEffect(() => {
    function checkLogin() {
      onAuthStateChanged(auth, (user) => {
        setIsLoggedIn(!!user);
      });
    }

    checkLogin();

    async function getEntries() {
      const jobHistoryEntriesRef = await getDocs(collection(db, "jobHistory"));
      const entries = jobHistoryEntriesRef.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setEntries(entries);

      const averageExperiencePerLevel = await getAverageTotalExperiencePerLevel(entries, companyName.companyName);
      const averageAgePerLevel = await getAverageAgePerLevel(entries, companyName.companyName);
      const averageTimePerLevel = await getAverageTimePerLevel(entries, companyName.companyName);
      const totalMajorsPerCompany = await getTotalMajorsPerCompany(entries, companyName.companyName);
      const universityCountPerCompany = await getTopUniversitiesPerCompany(entries, companyName.companyName);
      const countsForPreviousCompanies = await getHighestCountsForPreviousCompany(entries, companyName.companyName);
      // const countsForAllRacesForAllLevels = await getAllRacesForAllLevels(entries, companyName.companyName);
      const countsForRacePerLevel = await getTopRacesPerLevel(entries, companyName.companyName, selectedLevel);
      const countsForGenderPerLevel = await getGenderPerLevel(entries, companyName.companyName, selectedLevel);
      const promotionInfoPerLevel = await getHowPromotedPerLevel(entries, companyName.companyName, selectedLevel);

      setTotalExperiencePerLevel(averageExperiencePerLevel);
      setAverageAgePerLevel(averageAgePerLevel);
      setAverageTimePerLevel(averageTimePerLevel);
      setTotalMajorsPerCompany(totalMajorsPerCompany);
      setUniversityCountPerCompany(universityCountPerCompany);
      setCountsForPreviousCompanies(countsForPreviousCompanies);
      // setCountsForAllRacesForAllLevels(countsForAllRacesForAllLevels);
      setcountsForRacePerLevel(countsForRacePerLevel);
      setCountsForGenderPerLevel(countsForGenderPerLevel);
      setPromotionInfoPerLevel(promotionInfoPerLevel);
    }

    getEntries();
  }, []);

  useEffect(() => {
    setTotalExperienceChartData({
      labels: totalExperiencePerLevel.map((data) => data.id),
      datasets: [
        {
          label: "Total Years of Experience ",
          data: totalExperiencePerLevel.map((data) => data.averageYears),
          backgroundColor: totalExperiencePerLevel.map((data, index) => getColor(index)),
          borderColor: "black",
          borderWidth: 2
        }
      ]
    });

    setAverageAgePerLevelChartData({
      labels: averageAgePerLevel.map((data) => data.id),
      datasets: [
        {
          label: "Average Age",
          data: averageAgePerLevel.map((data) => data.averageAge),
          backgroundColor: averageAgePerLevel.map((data, index) => getColor(index)),
          borderColor: "black",
          borderWidth: 2
        }
      ]
    });

    setAverageTimePerLevelChartData({
      labels: averageTimePerLevel.map((data) => data.id),
      datasets: [
        {
          label: "Average Time in years",
          data: averageTimePerLevel.map((data) => data.averageTime),
          backgroundColor: averageTimePerLevel.map((data, index) => getColor(index)),
          borderColor: "black",
          borderWidth: 2
        }
      ]
    });

    setTotalMajorPerCompanyChartData({
      labels: totalMajorsPerLevel.map((data) => data.id),
      datasets: [
        {
          label: "Count",
          data: totalMajorsPerLevel.map((data) => data.count),
          backgroundColor: totalMajorsPerLevel.map((data, index) => getColor(index)),
          borderColor: "black",
          borderWidth: 2
        }
      ]
    });

    setUniversityCountPerCompanyChartData({
      labels: universityCountPerCompany.map((data) => data.id),
      datasets: [
        {
          label: "Count",
          data: universityCountPerCompany.map((data) => data.universityCount),
          backgroundColor: universityCountPerCompany.map((data, index) => getColor(index)),
          borderColor: "black",
          borderWidth: 2
        }
      ]
    });

    setCountsForPreviousCompaniesChartData({
      labels: countsForPreviousCompanies.map((data) => data.id),
      datasets: [
        {
          label: "Count",
          data: countsForPreviousCompanies.map((data) => data.count),
          backgroundColor: countsForPreviousCompanies.map((data, index) => getColor(index)),
          borderColor: "black",
          borderWidth: 2
        }
      ]
    });

    // const chartData = {
    //   labels: ['Category 1', 'Category 2', 'Category 3'], // Labels for the x-axis categories
    //   datasets: [
    //     {
    //       label: 'Group 1', // Label for the first group of bars
    //       data: [10, 15, 8], // Data values for the bars in Group 1
    //       backgroundColor: 'rgba(255, 99, 132, 0.5)', // Background color for the bars in Group 1
    //     },
    //     {
    //       label: 'Group 2', // Label for the second group of bars
    //       data: [7, 12, 14], // Data values for the bars in Group 2
    //       backgroundColor: 'rgba(54, 162, 235, 0.5)', // Background color for the bars in Group 2
    //     },
    //     // Add more datasets for additional groups if needed
    //   ],
    // };

    setCountsForAllRacesForAllLevelsChartData({
      labels: Object.values(levelOptions),
      datasets: countsForAllRacesForAllLevels.flatMap((item) => item.data.map((race) => ({
        label: race.label,
        data: race.data,
        backgroundColor: getColor(), // Replace with appropriate color assignment logic
        borderColor: "black",
        borderWidth: 2
      })))
    }
    );

    setcountsForRacePerLevelChartData({
      labels: countsForRacePerLevel.map((data) => data.id),
      datasets: [
        {
          label: "Count",
          data: countsForRacePerLevel.map((data) => data.count),
          backgroundColor: countsForRacePerLevel.map((data, index) => getColor(index)),
          borderColor: "black",
          borderWidth: 2
        }
      ]
    });

    setCountsForGenderPerLevelChartData({
      labels: countsForGenderPerLevel.map((data) => data.id),
      datasets: [
        {
          label: "Count",
          data: countsForGenderPerLevel.map((data) => data.count),
          backgroundColor: countsForGenderPerLevel.map((data, index) => getColor(index)),
          borderColor: "black",
          borderWidth: 2
        }
      ]
    });

  }, [totalExperiencePerLevel, averageAgePerLevel, averageTimePerLevel, totalMajorsPerLevel, universityCountPerCompany, countsForPreviousCompanies, countsForRacePerLevel, countsForGenderPerLevel]);

  return (
    <div className="App">
      <section class="bg-white dark:bg-gray-900">
        <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-3 lg:py-16 lg:px-6">
          {totalExperiencePerLevel.length > 0 ? (
            <BarChart title="Average of Total Experience Per Level" chartData={totalExperienceChartData} />
          ) : (
            <Spinner />
          )}

          {averageAgePerLevel.length > 0 ? (
            <BarChart title="Average Age Per Level" chartData={averageAgePerLevelChartData} />
          ) : (
            <Spinner />
          )}

          {averageTimePerLevel.length > 0 ? (
            <BarChart title="Average Years In Role Per Level" chartData={averageTimePerLevelChartData} />
          ) : (
            <Spinner />
          )}

        </div>

        <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-3 lg:py-16 lg:px-6">
          {totalMajorsPerLevel.length > 0 ? (
            <PieChart title="Majors Breakdown" chartData={totalMajorPerCompanyChartData} />
          ) : (
            <Spinner />
          )}

          {countsForPreviousCompanies.length > 0 ? (
            <PieChart title="Previous Companies" chartData={countsForPreviousCompaniesChartData} x={countsForPreviousCompanies} />
          ) : (
            <Spinner />
          )}

          {universityCountPerCompany.length > 0 ? (
            <PieChart title="Top Universities" chartData={universityCountPerCompanyChartData} />
          ) : (
            <Spinner />
          )}

        </div>

        <div class="bg-neutral-50 px-6 text-center text-neutral-800 dark:bg-neutral-700 dark:text-neutral-200">
          <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-4xl dark:text-white">Data Per Level</h1>
          <div class="flex justify-center space-x-2">
            <select value={selectedLevel} onChange={(e) => getDemographicPerLevel(entries, companyName, e.target.value, setSelectedLevel, setcountsForRacePerLevel, setcountsForRacePerLevelChartData, setCountsForGenderPerLevel, setCountsForGenderPerLevelChartData, setPromotionInfoPerLevel)}
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 text-center mr-2 mb-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              {Object.values(levelOptions).map((level) => (
                <option key={level} value={level}>
                  {level}
                </option>
              ))}
            </select>
          </div>

          <div>
            <div className="App">
              <section class="bg-white dark:bg-gray-900">
                <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-1 lg:py-16 lg:px-6">
                  <HowTable title={`Entries for ${selectedLevel}`} entries={promotionInfoPerLevel} />
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="bg-neutral-50 py-20 px-6 text-center text-neutral-800 dark:bg-neutral-700 dark:text-neutral-200">
          <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-4xl dark:text-white">Demographic Data</h1>
          <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-3 lg:py-16 lg:px-6">
            {countsForRacePerLevel.length > 0 ? (
              <PieChart title={`Race breakdown ${selectedLevel}`} chartData={countsForRacePerLevelChartData} />
            ) : (
              <Spinner />
            )}

            {countsForGenderPerLevel.length > 0 ? (
              <PieChart title={`Gender breakdown ${selectedLevel}`} chartData={countsForGenderPerLevelChartData} />
            ) : (
              <Spinner />
            )}

            {/* {countsForAllRacesForAllLevels.length > 0 ? (
            <GroupedBarChart title="all races" chartData={countsForAllRacesForAllLevelsChartData} x={countsForAllRacesForAllLevelsChartData} />
          ) : (
            <Spinner />
          )} */}

            {/* <GroupedBarChart title="all races" chartData={chartData}/> */}
          </div>
        </div>
      </section>
    </div >
  );
}

import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import googleLogo from '../../images/googleLogo.png';

function handleErrorMessage(error, setError) {
    const errorCode = error.code;
    let errorMessage = '';

    if (errorCode === 'auth/user-not-found') {
        errorMessage = 'Invalid email or password';
    } else if (errorCode === 'auth/wrong-password') {
        errorMessage = 'Invalid email or password';
    } else if (errorCode === 'auth/popup-closed-by-user') {
        errorMessage = 'Sign-in cancelled';
    } else {
        errorMessage = 'An error occurred';
    }

    setError(errorMessage);
}

export const SignInForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSignIn = async (e) => {
        e.preventDefault();

        const auth = getAuth();

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            window.location.href = '/home';
        } catch (error) {
            handleErrorMessage(error, setError);
        }
    };

    const handleGoogleSignIn = async () => {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();

        try {
            const userCredential = await signInWithPopup(auth, provider);
            const user = userCredential.user;
            window.location.href = '/home';
        } catch (error) {
            handleErrorMessage(error, setError);
        }
    };

    return (
        <section className="bg-gray-50 dark:bg-gray-900">
            <div className="flex justify-center items-center h-screen">
                <div className="w-full max-w-md p-6 space-y-8 bg-white rounded-lg shadow-xl dark:bg-gray-800 mt-[-50vh]">
                    <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Sign in to CareerMap</h2>
                    <form className="mt-8 space-y-6" onSubmit={handleSignIn}>
                        <div>
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Your email
                            </label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="name@company.com"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Your password
                            </label>
                            <input
                                type="password"
                                name="password"
                                id="password"
                                placeholder="••••••••"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="flex items-start">
                            <div className="flex items-center h-5">
                                <input
                                    id="remember"
                                    aria-describedby="remember"
                                    name="remember"
                                    type="checkbox"
                                    className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                                />
                            </div>
                            <div className="ml-3 text-sm">
                                <label htmlFor="remember" className="font-medium text-gray-500 dark:text-gray-400">
                                    Remember this device
                                </label>
                            </div>
                            {/* <a href="#" className="ml-auto text-sm font-medium text-blue-600 hover:underline dark:text-blue-500">
                  Lost Password?
                </a> */}
                        </div>
                        {error && <p className="text-red-500 text-sm">{error}</p>}
                        <button
                            type="submit"
                            className="w-full px-5 py-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 sm:w-auto dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            Login to your account
                        </button>
                        <div className="text-sm font-medium text-gray-900 dark:text-white">
                            Not registered yet?{' '}
                            <a href="/signup" className="text-blue-600 hover:underline dark:text-blue-500">Create account</a>
                        </div>

                        <div className="flex items-center justify-center">
                            <span className="text-gray-500">Or sign in with</span>
                            <button
                                type="button"
                                onClick={handleGoogleSignIn}
                                className="ml-2 p-1.5 border border-gray-300 rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <img src={googleLogo} alt="Google Logo" className="h-6 w-6" />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};
import "../../index.css";
import LineChart from "../../components/charts/LineChart";
import { HorizontalBarChart } from "../../components/charts/HorizontalBarChart";
import { useState, useEffect } from "react";
import { Data, barDataExport } from "../../components/Data";
import worksenselogo from '../../images/worksenselogo.png';
import { db } from '../../firebase';
import { collection, getDocs, limitToLast, orderBy, query, startAfter, startAt, where, and } from "firebase/firestore";
import { initFlowbite } from 'flowbite'

export default function Dashboard() {
    useEffect(() => {
        initFlowbite();
    })

    const [sentimentScores, setSentimentScores] = useState("")
    const [overallSentimentScore, setOverallSentimentScore] = useState("");

    async function getSentimentScores() {
        var ts = Math.round(new Date().getTime() / 1000);
        var tsYesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));

        const recentScoresRef = query(collection(db, "teams"), where("ts", ">", new Date(tsYesterday)), orderBy("ts"));
        const scores = (await getDocs(recentScoresRef)).docs.map((doc) => ({
            ts: doc.ts ? doc.ts.toDate()['seconds'] : "l",
            ...doc.data(),
        }));

        setSentimentScores(scores);

        // Function to find average of sentiment scores
        var total = 0;
        for (var i = 0; i < scores.length; i++) {
            total += scores[i]['score'];
        }

        var avg = total / scores.length;
        setOverallSentimentScore(Math.round(avg));
    }

    getSentimentScores();

    const [chartData, setChartData] = useState({
        labels: Data.map((data) => data.day),
        datasets: [
            {
                label: "Overall Score",
                data: Data.map((data) => data.userGain),
                backgroundColor: [
                    "rgba(75,192,192,1)",
                    "#ecf0f1",
                    "#50AF95",
                    "#f3ba2f",
                    "#2a71d0"
                ],
                borderColor: "black",
                borderWidth: 2
            }
        ]
    });

    const [barGraphData, setBarGraphData] = useState({
        labels: barDataExport.map((data) => data.channel),
        datasets: [
            {
                label: "Overall Score",
                data: barDataExport.map((data) => data.average),
                backgroundColor: [
                    "rgba(75,192,192,1)",
                    "#ecf0f1",
                    "#50AF95",
                    "#f3ba2f",
                    "#2a71d0"
                ],
                borderColor: "black",
                borderWidth: 2
            }
        ]
    });

    return (
        <>
            <div class="antialiased bg-gray-50 dark:bg-gray-900">
                <main class="p-4 md:ml-64 h-auto pt-20">
                    <div class="grid gap-4">
                        <div class="grid grid-cols-2 md:grid-cols-2 gap-4">
                            <div>
                                <p class="text-3xl text-center text-black-500 dark:text-gray-400">Overall Sentiment Score</p>
                                <p class="text-6xl text-center text-black-500 dark:text-gray-400 font-bold p-12">{overallSentimentScore}</p>

                                {/* </div> */}
                                <h1 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-center"></h1>
                            </div>
                            <div>
                                <LineChart title="Sentiment Trend - week over week" chartData={chartData} />
                            </div>
                        </div>
                        <div class="grid grid-cols-2 md:grid-cols-2 gap-4">
                            <div>
                                <HorizontalBarChart class="h-48 md:h-72" title="Sentiment Score by team" chartData={barGraphData} />
                            </div>
                        </div>
                    </div>
                </main>

                <nav class="bg-white border-b border-gray-200 px-4 py-2.5 dark:bg-gray-800 dark:border-gray-700 fixed left-0 right-0 top-0 z-50">
                    <div class="flex flex-wrap justify-between items-center">
                        <div class="flex justify-start items-center">
                            <button
                                data-drawer-target="drawer-navigation"
                                data-drawer-toggle="drawer-navigation"
                                aria-controls="drawer-navigation"
                                class="p-2 mr-2 text-gray-600 rounded-lg cursor-pointer md:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                            >
                                <svg
                                    aria-hidden="true"
                                    class="w-6 h-6"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                                <svg
                                    aria-hidden="true"
                                    class="hidden w-6 h-6"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                                <span class="sr-only">Toggle sidebar</span>
                            </button>
                            <a href="https://careermap.fyi" class="flex items-center justify-between mr-4">
                                <img
                                    src={worksenselogo}
                                    class="mr-3 h-8"
                                    alt="Worksense Logo"
                                />
                                <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Worksense</span>
                            </a>
                        </div>
                        <div class="flex items-center lg:order-2">

                            <button
                                type="button"
                                class="flex mx-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                                id="user-menu-button"
                                aria-expanded="false"
                                data-dropdown-toggle="dropdown"
                            >
                                <span class="sr-only">Open user menu</span>
                                <img
                                    class="w-8 h-8 rounded-full"
                                    src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/michael-gough.png"
                                    alt="user photo"
                                />
                            </button>

                            <div
                                class="hidden z-50 my-4 w-56 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600 rounded-xl"
                                id="dropdown"
                            >
                                <div class="py-3 px-4">
                                    <span class="block text-sm font-semibold text-gray-900 dark:text-white">Admin</span>
                                    <span class="block text-sm text-gray-900 truncate dark:text-white">admin@company.com</span>
                                </div>
                                <ul
                                    class="py-1 text-gray-700 dark:text-gray-300"
                                    aria-labelledby="dropdown"
                                >
                                    <li>
                                        <a href="#" class="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">Account settings</a>
                                    </li>
                                </ul>
                                <ul class="py-1 text-gray-700 dark:text-gray-300" aria-labelledby="dropdown">
                                    <li>
                                        <a
                                            href="#"
                                            class="flex items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                        ><svg
                                            class="mr-2 w-5 h-5 text-gray-400"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                                <path
                                                    d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"
                                                ></path>
                                            </svg>
                                            Collections</a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            class="flex justify-between items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                        >
                                            <span class="flex items-center">
                                                <svg
                                                    aria-hidden="true"
                                                    class="mr-2 w-5 h-5 text-primary-600 dark:text-primary-500"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                </svg>
                                                Pro version
                                            </span>
                                            <svg
                                                aria-hidden="true"
                                                class="w-5 h-5 text-gray-400"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                                <ul
                                    class="py-1 text-gray-700 dark:text-gray-300"
                                    aria-labelledby="dropdown"
                                >
                                    <li>
                                        <a
                                            href="#"
                                            class="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                        >Sign out</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>

                <aside
                    class="fixed top-0 left-0 z-40 w-64 h-screen pt-14 transition-transform -translate-x-full bg-white border-r border-gray-200 md:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
                    aria-label="Sidenav"
                    id="drawer-navigation"
                >
                    <div class="overflow-y-auto py-5 px-3 h-full bg-white dark:bg-gray-800">
                        <ul class="space-y-2">
                            <li>
                                <button
                                    type="button"
                                    class="flex items-center p-2 w-full text-base font-medium text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                    aria-controls="dropdown-dashboard"
                                    data-collapse-toggle="dropdown-dashboard"
                                >
                                    <svg
                                        aria-hidden="true"
                                        class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                                        <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                                    </svg>
                                    <span class="flex-1 ml-3 text-left whitespace-nowrap">Dashboard</span>
                                    <svg
                                        aria-hidden="true"
                                        class="w-6 h-6"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </button>
                                <ul id="dropdown-dashboard" class="hidden py-2 space-y-2">
                                    <li>
                                        <a
                                            href="#"
                                            class="flex items-center p-2 pl-11 w-full text-base font-medium text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                        >Sentiment</a
                                        >
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            class="flex items-center p-2 pl-11 w-full text-base font-medium text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                        >Productivity</a
                                        >
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <div class="hidden absolute bottom-0 left-0 justify-center p-4 space-x-4 w-full lg:flex bg-white dark:bg-gray-800 z-20">
                        <a
                            href="#"
                            class="inline-flex justify-center p-2 text-gray-500 rounded cursor-pointer dark:text-gray-400 hover:text-gray-900 dark:hover:text-white hover:bg-gray-100 dark:hover:bg-gray-600"
                        >
                            <svg
                                aria-hidden="true"
                                class="w-6 h-6"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"
                                ></path>
                            </svg>
                        </a>
                    </div>
                </aside>
            </div>
        </>
    )
}
import { useEffect } from "react";
import { Bar } from "react-chartjs-2";

export const BarChart = ({ title, chartData, x }) => {
  // useEffect(() => {
  //   if (x) {
  //     console.log(x);
  //   }
  // })

  return (
    <div className="chart-container">
      <h2 style={{ textAlign: "center" }}>{title}</h2>
      <Bar
        data={chartData}
        options={{
          plugins: {
            title: {
              display: true,
              // text: "Users Gained between 2016-2020"
            },
            legend: {
              display: false
            }
          }
        }}
      />
    </div>
  );
};
import './../../css/App.css';
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { useState, useEffect } from "react";
import AdminTable from '../../components/AdminTable';
import { doc, getDoc, getDocFromCache, getDocs, collection, orderBy, query } from "firebase/firestore";
import { db } from '../../firebase';

Chart.register(CategoryScale);

export default function Admin() {
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    async function getEntries() {
      const jobHistoryEntriesRef = await getDocs(collection(db, "jobHistory"));
      // // const q = query(jobHistoryEntriesRef, orderBy("timeStamp"));
      // const jobHistoryEntriesRef = db.collection("jobHistory");
      // const query = jobHistoryEntriesRef.query().orderBy("timestamp");

      // const querySnapshot = await query.get();
      // console.log(querySnapshot);
      const entries = jobHistoryEntriesRef.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setEntries(entries);
    }

    getEntries();
  }, []);

  return (
    <>
      <div class="bg-neutral-50 py-20 px-6 text-center text-neutral-800 dark:bg-neutral-700 dark:text-neutral-200">
        <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">Admin Page</h1>
        <p class="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">Additional Insights</p>
      </div>

      <div className="App">
        <section class="bg-white dark:bg-gray-900">

          <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-1 lg:py-16 lg:px-6">
            <AdminTable entries={entries} />
          </div>
        </section>
      </div>
    </>

  );
}

import { db } from '../firebase';
import { collection, getDocs, limitToLast, orderBy, query, startAfter, startAt, where, and } from "firebase/firestore";
import { ref as sRef, orderByChild } from "firebase/database";

var ts = Math.round(new Date().getTime() / 1000);
var tsLastWeek = new Date(new Date().getTime() - (168 * 60 * 60 * 1000));
var date_map = {
  "Monday": [],
  "Tuesday": [],
  "Wednesday": [],
  "Thursday": [],
  "Friday": []
}

var date_avg = {
  "Monday": 0,
  "Tuesday": 0,
  "Wednesday": 0,
  "Thursday": 0,
  "Friday": 0
}

const recentScoresRef = query(collection(db, "teams"), where("ts", ">", new Date(tsLastWeek)), orderBy("ts"));
const scores = (await getDocs(recentScoresRef)).docs.map((doc) => ({
  ts: doc.ts ? doc.ts.toDate()['seconds'] : "l",
  ...doc.data(),
}));

scores.forEach((score) => {
  if (new Date(score['ts']['seconds'] * 1000).getDay() == 1) {
    date_map["Monday"].push(score);
  } else if (new Date(score['ts']['seconds'] * 1000).getDay() == 2) {
    date_map["Tuesday"].push(score);
  } else if (new Date(score['ts']['seconds'] * 1000).getDay() == 3) {
    date_map["Wednesday"].push(score);
  } else if (new Date(score['ts']['seconds'] * 1000).getDay() == 4) {
    date_map["Thursday"].push(score);
  } else if (new Date(score['ts']['seconds'] * 1000).getDay() == 5) {
    date_map["Friday"].push(score);
  }
});


for (const [key, value] of Object.entries(date_map)) {
  var total = 0;
  for (var i = 0; i < value.length; i++) {
    total += value[i]['score'];
  }
  var avg = total / value.length;
  date_avg[key] = avg;
}

console.log(date_avg);

var channel_score = {};

scores.forEach((score) => {
  if (score['channel'] in channel_score) {
    channel_score[score['channel']].push(score);
  } else {
    channel_score[score['channel']] = []
  }
});

var barData = [];
for (var key in channel_score) {
  var total = 0;
  for (var i = 0; i < channel_score[key].length; i++) {
    total += channel_score[key][i]['score'];
  }
  var avg = total / channel_score[key].length;
  var object = {
    channel: key,
    average: avg
  }
  barData.push(object);
}

export const barDataExport = barData;

// Data for line graph
export const Data = [
  {
    id: 1,
    day: "Monday",
    userGain: date_avg["Monday"] ? date_avg["Monday"] : 0,
    userLost: 823
  },
  {
    id: 2,
    day: "Tuesday",
    userGain: date_avg["Tuesday"] ? date_avg["Tuesday"] : 0,
    userLost: 345
  },
  {
    id: 3,
    day: "Wednesday",
    userGain: date_avg["Wednesday"] ? date_avg["Wednesday"] : 0,
    userLost: 555
  },
  {
    id: 4,
    day: "Thursday",
    userGain: date_avg["Thursday"] ? date_avg["Thursday"] : 0,
    userLost: 4555
  },
  {
    id: 5,
    day: "Friday",
    userGain: date_avg["Friday"] ? date_avg["Friday"] : 0,
    userLost: 234
  }
];
import lookatme from '../../images/lookatme.jpg';

export default function Thanks() {

    return (
        <>
            <section class="bg-white dark:bg-gray-900">
                <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                    <div class="space-y-8 md:grid md:grid-cols-1 lg:grid-cols-1 md:gap-12 md:space-y-0">
                        <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Thank you for signing up!  We'll notify you when we launch.</h2>
                        <p class="text-black-500 sm:text-xl dark:text-black-400">You're on your way to career growth.  Enough of these companies giving you false promises, we're going to give you the real scoop on how to get that promotion. Stay tuned!</p>
                        <p class="text-black-500 sm:text-xl dark:text-black-400">In the meantime, share CareerMap with your engineering friends!  And what do you want to see?  Give us feedback with <a href="https://forms.gle/zqqCUzvezbg4Pdqn7"><u>this form</u></a>. We read every message.</p>
                    </div>

                    <div class="space-y-8 md:grid md:grid-cols-1 lg:grid-cols-1 md:gap-12 md:space-y-0">
                        <img src={lookatme} alt="get it" />
                    </div>
                </div>
            </section>
        </>
    )
}
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
export const firebaseApp = initializeApp({
  apiKey: "AIzaSyDLCAeSIR2_QrbZamaH6j6gRdGuCJ0WjFI",
  authDomain: "career-mapper.firebaseapp.com",
  databaseURL: "https://career-mapper-default-rtdb.firebaseio.com",
  projectId: "career-mapper",
  storageBucket: "career-mapper.appspot.com",
  messagingSenderId: "188580275547",
  appId: "1:188580275547:web:3434a3f56a130fbf3d267e",
  measurementId: "G-76FXELHJY2"
})

const analytics = getAnalytics(firebaseApp)
export const db = getFirestore(firebaseApp)
export const auth = getAuth(firebaseApp);
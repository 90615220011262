export default function OtherForm({ setCurrentLocation, setTotalYears }) {
    return (
        <div>
            <h1 className="text-base font-semibold leading-7 pb-2 text-gray-900">Other Information</h1>

            <div class="grid md:grid-cols-2 md:gap-6">
                <div class="relative z-0 w-full mb-6 group">
                <label for="total_years" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Total Years of Experience</label>
                    <input type="number" name="total_years" id="total_years" aria-describedby="helper-text-explanation" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={e => setTotalYears(e.target.value)}
                    placeholder="10" required />
                </div>
                <div class="relative z-0 w-full mb-6 group">
                <label for="current_location" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Current Location</label>
                    <input type="text" name="current_location" id="current_location" aria-describedby="helper-text-explanation" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={e => setCurrentLocation(e.target.value)}
                    placeholder="Bay Area" required />
                </div>
            </div>
        </div>
    )
}

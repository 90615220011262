import { useState } from "react";
import { levelOptions, techCompanies } from "../Constants";

export default function CompanyForm({company, companyIndex, companies, setCompanies, handleCompanyNameChange, handleAddRole}) {
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredCompanies, setFilteredCompanies] = useState([]);

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);

        const filtered = Array.from(techCompanies).filter((company) =>
            company.toLowerCase().includes(query)
        );

        setFilteredCompanies(filtered);
    };

    return (
        <div key={companyIndex}>
            <div>
                <div className="grid md:grid-cols-2 md:gap-6">
                    <div className="relative z-0 w-full mb-6 group">
                        <label htmlFor={`name-${companyIndex}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Company
                        </label>
                        <input
                            type="text"
                            name="name"
                            id={`name-${companyIndex}`}
                            aria-describedby="helper-text-explanation"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            value={company.name}
                            onChange={(e) => handleCompanyNameChange(companyIndex, e)}
                            placeholder="Google"
                            required
                        />
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                        <label htmlFor="stage" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Stage
                        </label>
                        <select
                            type="text"
                            name="stage"
                            id="stage"
                            aria-describedby="helper-text-explanation"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            onChange={(e) => {
                                const newCompanies = [...companies];
                                newCompanies[companyIndex] = { ...company, stage: e.target.value };
                                setCompanies(newCompanies);
                            }}
                            placeholder="Google"
                            required
                        >
                            {/* <option value="" selected="selected" disabled="disabled">
                                -- select one --
                            </option> */}
                            <option>Pre-seed</option>
                            <option>Series A</option>
                            <option>Series B</option>
                            <option>Series C</option>
                            <option>Series D</option>
                            <option>Series E</option>
                            <option>Series F</option>
                            <option selected="selected">Public</option>
                        </select>
                    </div>
                </div>
            </div>

            {company.roles.map((role, roleIndex) => (
                <div key={roleIndex}>
                    <h4 className="text-base font-semibold leading-7 pb-2 text-gray-900">Role Info</h4>
                    <div class="grid md:grid-cols-2 md:gap-6">
                        <div class="relative z-0 w-full mb-6 group">
                            <label for="level" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Level</label>
                            <select type="text" name="level" id="level" aria-describedby="helper-text-explanation" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={(e) => {
                                    const newCompanies = [...companies];
                                    const newCompany = { ...newCompanies[companyIndex] };
                                    const newRoles = [...newCompany.roles];
                                    newRoles[roleIndex] = { ...role, level: e.target.value.substring(0, 3).trim() };
                                    newCompany.roles = newRoles;
                                    newCompanies[companyIndex] = newCompany;
                                    setCompanies(newCompanies);
                                }}
                                placeholder="L5" required>
                                <option value="" selected="selected" disabled="disabled">-- select one --</option>
                                <option>{levelOptions.L3} - Entry-Level SWE</option>
                                <option>{levelOptions.L4} - Experienced SWE</option>
                                <option>{levelOptions.L5} - Senior SWE</option>
                                <option>{levelOptions.L6} - Staff SWE</option>
                                <option>{levelOptions.L7} - Senior Staff SWE</option>
                                <option>{levelOptions.L8} - Principal SWE</option>
                                <option>{levelOptions.L9} - Distinguished SWE</option>
                                <option>{levelOptions.L10} - Fellow</option>
                            </select>
                        </div>
                        <div class="relative z-0 w-full mb-6 group">
                            <label for="time" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Time in role (years)</label>
                            <input type="text" name="time" id="time" aria-describedby="helper-text-explanation" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={role.time}
                                onChange={(e) => {
                                    const newCompanies = [...companies];
                                    const newCompany = { ...newCompanies[companyIndex] };
                                    const newRoles = [...newCompany.roles];
                                    newRoles[roleIndex] = { ...role, time: e.target.value };
                                    newCompany.roles = newRoles;
                                    newCompanies[companyIndex] = newCompany;
                                    setCompanies(newCompanies);
                                }}
                                placeholder="5" required />
                        </div>
                    </div>

                    <div class="relative z-0 w-full mb-6 group">
                        <label for="promotion" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">How did you get promoted?</label>
                        <textarea type="text" rows="4" name="promotion" id="promotion" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            value={role.promotion}
                            onChange={(e) => {
                                const newCompanies = [...companies];
                                const newCompany = { ...newCompanies[companyIndex] };
                                const newRoles = [...newCompany.roles];
                                newRoles[roleIndex] = { ...role, promotion: e.target.value };
                                newCompany.roles = newRoles;
                                newCompanies[companyIndex] = newCompany;
                                setCompanies(newCompanies);
                            }}
                            placeholder="Add a couple of sentences on how you got promoted to help others out" required />
                    </div>
                </div>
            ))}

            <button
                type="button"
                className="text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
                onClick={() => handleAddRole(companyIndex)}
            >
                Add Role
            </button>
        </div>
    );
};
export default function AdminTable({entries}) {

  const formatDate = (timestamp) => {
    return new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(timestamp)
  }

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <caption className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
          Entries
          <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">
            Number of entries: <b>{entries.length}</b>
          </p>
        </caption>
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              Companies
            </th>
            <th scope="col" className="px-6 py-3">
              Education
            </th>
            <th scope="col" className="px-6 py-3">
              Demographic
            </th>
            <th scope="col" className="px-6 py-3">
              Other
            </th>
            <th scope="col" className="px-6 py-3">
              Timestamp
            </th>
          </tr>
        </thead>
        <tbody>
          {entries.map((entry) => (
            <tr
              key={entry.id}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              <td className="px-6 py-4">
                {entry.companies.map((company) => (
                  <div key={company.name}>
                    <p><b>Company Info</b></p>
                    <p>Company Name: {company.name}</p>
                    <p>Stage: {company.stage}</p>
                    <ul className="pl-4">
                      {company.roles.map((role) => (
                        <li key={role.level}>
                          ---------Role---------
                          <ul>
                            <li><b>Level:</b> {role.level}</li>
                            <li><b>Time:</b> {role.time} year(s)</li>
                            <li><b>How:</b> {role.promotion}</li>
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </td>
              <td className="px-6 py-4">
                <p><b>School:</b> {entry.education.school}</p>
                <p><b>Degree:</b> {entry.education.degree}</p>
                <p><b>Major:</b> {entry.education.major}</p>
                <p><b>Year:</b> {entry.education.year}</p>
              </td>
              <td className="px-6 py-4">
                <p><b>Gender:</b> {entry.demographic.gender}</p>
                <p><b>Age:</b> {entry.demographic.age}</p>
                <p><b>DOB:</b> {entry.demographic.dob}</p>
                <p><b>Race:</b> {entry.demographic.race}</p>
              </td>
              <td className="px-6 py-4">
                <p><b>Total Years:</b> {entry.other.totalYears}</p>
                <p><b>Current Location:</b> {entry.other.currentLocation}</p>
              </td>
              <td className="px-6 py-4">
                {entry.timestamp && entry.timestamp.currentTime && (<p>{formatDate(entry.timestamp.currentTime)}</p>)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
import EducationForm from './forms/Education';
import DemographicForm from './forms/Demographics';
import OtherForm from './forms/OtherInfo';
import { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc, doc, getDocs, setDoc } from "firebase/firestore";
import CompanyForm from './forms/CompanyForm';


export default function JobHistory() {
    // company information
    const [companies, setCompanies] = useState([{
        name: '',
        stage: '',
        roles: []
    }]);

    // education information
    const [school, setSchool] = useState('');
    const [degree, setDegree] = useState('');
    const [major, setMajor] = useState('');
    const [year, setYear] = useState('');

    //demographic information
    const [dob, setDob] = useState('');
    const [race, setRace] = useState('');
    const [gender, setGender] = useState('');

    // other info
    const [totalYears, setTotalYears] = useState('');
    const [currentLocation, setCurrentLocation] = useState('');
    const [errorMessage, setErrorMessage] = useState({});

    const addJobHistory = async (e) => {
        e.preventDefault();

        if (!degree) {
            setErrorMessage({
                id: "degree",
                message: 'Please select a degree'
            });
            return;
        }

        // if (isNaN(parseInt(age)) || age < 14 || age > 110) {
        //     setErrorMessage({
        //         id: "age",
        //         message: 'You must enter a valid age.  Min is 14 years and 110 is the maximum.  No decimals.'

        //     });
        //     return;
        // }

        // if (!race) {
        //     setErrorMessage({
        //         id: "race",
        //         message: 'Please Select a race.'
        //     });
        //     return;
        // }

        if (!gender) {
            setErrorMessage({
                id: "gender",
                message: 'Please select a gender'
            });
            return;
        }

        const currentTime = new Date().getTime();

        const jobHistoryRef = await setDoc(doc(collection(db, "jobHistory")), {
            companies: companies,
            education: { school, degree, major, year },
            demographic: { dob, race, gender },
            other: { totalYears, currentLocation },
            timestamp: { currentTime }
        });

        window.location.href = '/thanks';

        // window.location.reload();
    }

    const handleCompanyNameChange = (companyIndex, event) => {
        const newCompanies = [...companies];
        newCompanies[companyIndex] = { ...newCompanies[companyIndex], name: event.target.value };
        setCompanies(newCompanies);
    };

    const handleAddCompany = () => {
        setCompanies(prevCompanies => [...prevCompanies, { name: '', roles: [] }
        ]);
    };

    const handleAddRole = (companyIndex) => {
        setCompanies(prevCompanies => {
            const newCompanies = [...prevCompanies];
            const newCompany = { ...newCompanies[companyIndex] };
            newCompany.roles = [...newCompany.roles, { level: '', time: '', promotion: '' }];
            newCompanies[companyIndex] = newCompany;
            return newCompanies;
        });
    };

    return (
        <section class="bg-white dark:bg-gray-900">
            <section>
                <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-1">
                    <div class="mr-auto place-self-center lg:col-span-7">
                        <h1 class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">Career Map</h1>
                        <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">Career insights that matter.</p>
                    </div>

                    <div>
                        <p>Join our waitlist by entering your work history and tell us how you got promoted. We’re crowdsourcing this data to provide you with actionable insights to grow your career!</p>
                    </div>
                </div>
            </section>

            <div class="gap-2 py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-1 lg:py-0 lg:px-6">
                <form>
                    <div className="space-y-5">
                        <div className="border-b border-gray-900/10 pb-1">
                            <h1 className="text-base font-semibold leading-7 text-gray-900">Job History</h1>
                        </div>

                        <h3 className="text-base font-semibold pb-2 text-gray-900">Company Info</h3>

                        {companies.map((company, companyIndex) => (
                            <CompanyForm company={company} companyIndex={companyIndex} companies={companies} setCompanies={setCompanies} handleCompanyNameChange={handleCompanyNameChange} handleAddRole={handleAddRole} />
                        ))}

                        <button type="button" class="text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
                            onClick={handleAddCompany}>
                            Add Company
                        </button>
                    </div>

                    <EducationForm
                        setSchool={setSchool}
                        setDegree={setDegree}
                        setMajor={setMajor}
                        setYear={setYear}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                    />
                    <DemographicForm
                        setDob={setDob}
                        setRace={setRace}
                        setGender={setGender}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                    />
                    <OtherForm
                        setTotalYears={setTotalYears}
                        setCurrentLocation={setCurrentLocation}
                    />

                    {errorMessage && (
                        <p id="helper-text-explanation" className="mt-2 text-xs text-red-600 dark:text-red-400">
                            <span className="font-medium">{errorMessage.message}</span>
                        </p>
                    )}

                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                            Cancel
                        </button>
                        <button
                            type="submit"
                            onClick={addJobHistory}
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </section>
    )
}
